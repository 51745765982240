import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"


const DirectTermsPage = () => (
  <Layout>
    <Seo title="Direct Sales Partner | Terms &amp; Conditions" />

    <section className="main--terms partners">
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
                <h2 className="section--header">Direct Sales Partner | Terms &amp; Conditions</h2>
                <p ><strong >These TERMS AND CONDITIONS</strong>&nbsp;(Terms&rdquo;) apply to and govern all products, and services provided by Vigilant LLC and its Affiliates (Vigilant&rdquo;) and any Person who sells, resells, or markets for sale, any Vigilant Products, software and services as directed by Vigilant and who enters into a Partnership Agreement with Vigilant (Direct Sales Partner&rdquo;). Vigilant reserves the right to revise or otherwise modify these Terms at any time with or without notice to Direct Sales Partner. If revised or modified, the revised Terms will be posted on this website and will thereafter be immediately effective for all later agreements between Vigilant and Direct Sales Partner or any amendments to any prior agreement entered into between the Vigilant and Direct Sales Partner.</p>
<p className="col--break">WHEREAS, Vigilant is in the business of developing and selling the Products (as defined below);</p>
<p className="col--break">WHEREAS, Vigilant wishes to engage Direct Sales Partner as an independent sales partner to solicit orders for the Products in the Territory (as such terms are defined below), subject to the terms and conditions of these Terms; and</p>

    <ol className="terms--content-list">
        <li> <h2>Definitions.</h2> Capitalized terms have the meanings set forth or referred to in this Section, or in the Section in which they first appear in these Terms.
            <div>
            <p className="col--break"><strong >Action</strong>&rdquo; means any claim, action, cause of action, demand, lawsuit, arbitration, inquiry, audit, notice of violation, proceeding, litigation, citation, summons, subpoena, or investigation of any nature, whether civil, criminal, administrative, regulatory, or other, and whether at law, in equity or otherwise.</p>
            <p className="col--break"><strong >Affiliate</strong>&rdquo; of a Person means any other Person that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such Person. The term control&rdquo; (including the terms controlled by&rdquo; and under common control with&rdquo;) means the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of a Person, whether through the ownership of voting securities, by contract or otherwise.</p>
            <p className="col--break"><strong >Business Day</strong>&rdquo; means any day except Saturday, Sunday, or any other day on which commercial banks located in Cincinnati, Ohio are authorized or required by Law to be closed for business.</p>
            <p className="col--break"><strong >Claim</strong>&rdquo; means any Action brought against a Person entitled to indemnification under&nbsp;Section&nbsp;12.</p>
            <p className="col--break"><strong >Commission</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;6.1.</p>
            <p className="col--break"><strong >Vigilant</strong>&rdquo; has the meaning set forth in the preamble of these Terms.</p>
            <p className="col--break" ><strong >Vigilant&rsquo;s Intellectual Property Rights</strong>&rdquo; means all Intellectual Property Rights owned by or licensed to Vigilant.</p>
            <p className="col--break"><strong >Vigilant&rsquo;s Trademarks</strong>&rdquo; means all Trademarks owned or licensed by Vigilant.</p>
            <p className="col--break"><strong >Confidential Information</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;10.</p>
            <p className="col--break"><strong >Customer</strong>&rdquo; means a purchaser except for an Excluded Customer that has acquired a Product through the sales efforts of Direct Sales Partner under these Terms.</p>
            <p className="col--break"><strong >Disclosing Party</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;10.1.</p>
            <p className="col--break"><strong >Effective Date</strong>&rdquo; means the effective date on any Partnership Agreement entered into between Direct Sales Partner and Vigilant that directly refers to and incorporates these Terms.</p>
            <p className="col--break"><strong >Excluded Customer</strong>&rdquo; means (i) a customer located outside of the Territory; or (ii) the accounts or Persons listed as an excluded customer in any Partnership Agreement; or (iii) any Person that Vigilant wishes to identify as an excluded customer that is delivered in writing to Direct Sales Partner at any time by Vigilant, in its sole discretion.</p>
            <p className="col--break"><strong >Force Majeure Event</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;16.19.</p>
            <p className="col--break"><strong >Governmental Authority</strong>&rdquo; means any federal, state, local, or foreign government or political subdivision thereof, or any agency or instrumentality of such government or political subdivision, or any self-regulated organization or other non-governmental regulatory authority or quasi-governmental authority (to the extent that the rules, regulations or orders of such organization or authority have the force of Law), or any arbitrator, court, or tribunal of competent jurisdiction.</p>
            <p className="col--break"><strong >Governmental Order</strong>&rdquo; means any order, writ, judgment, injunction, decree, stipulation, award, or determination entered by or with any Governmental Authority.</p>
            <p className="col--break"><strong >Indemnified Party</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;12.1.</p>
            <p className="col--break"><strong >Indemnifying Party</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;12.1.</p>
            <p className="col--break"><strong >Initial Term</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;9.1.</p>
            <p className="col--break"><strong >Intellectual Property Rights</strong>&rdquo; means all intellectual property and industrial property rights comprising or relating to (a) Patents; (b) Trademarks; (c) internet domain names, whether or not Trademarks, registered by any authorized private registrar or Governmental Authority, web addresses, web pages, websites, and URLs; (d) works of authorship, expressions, designs, and design registrations, whether or not copyrightable, including copyrights and copyrightable works, software, and firmware, application programming interfaces, architecture, files, records, schematics, data, data files, and databases and other specifications and documentation; (e) trade secrets; and (f) all other intellectual property and industrial property rights, and all rights, interests, and protections that are associated with, equivalent or similar to, or required for the exercise of, any of the foregoing, however arising, in each case whether registered or unregistered and including all registrations and applications for, and renewals or extensions of, such rights or forms of protection pursuant to the Laws of any jurisdiction in any part of the world.</p>
            <p className="col--break"><strong >Law</strong>&rdquo; means any statute, law, ordinance, regulation, rule, code, constitution, treaty, common law, Governmental Order, or other requirement or rule of law of any Governmental Authority.</p>
            <p className="col--break"><strong >Losses</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;12.1.</p>
            <p className="col--break"><strong >Minimum&nbsp;Sales&nbsp;Quota</strong>&rdquo; means the minimum sales commitments as set forth in <u>Exhibit A</u>. &nbsp;If no Minimum Sales Quota is set forth on Exhibit A, then this definition and Sections&nbsp;5.4&nbsp;and&nbsp;9.3(c)&nbsp;shall be inapplicable.</p>
            <p className="col--break"><strong >Monthly Report</strong>&rdquo; has the meaning set forth in Section&nbsp;4.1(e).</p>
            <p className="col--break">&nbsp;<strong >Net Sales Price</strong>&rdquo; means the net sales price (exclusive of sales tax and after applying any discounts, credits, rebates, or adjustments) of the Products sold pursuant to a Purchase Contract, as reflected on the invoice applicable to such sale, exclusive of any other amounts (if any) set forth on such invoice. For purposes of clarification, Net Sales Price does not include: (a) the value of any items that may be furnished by Vigilant to Customer without cost to Customer (for example, free products furnished as part of any advertising or promotions program); (b) any payments that Vigilant may receive under the Purchase Contract for services such as without limitation, installation, training, and maintenance, support, technical assistance, technical data, or documentation that may be furnished to the Customer by Vigilant; and (c) any reimbursement that may be received by Vigilant under the Purchase Contract for taxes, customs, duties, and the like.</p>
            <p className="col--break"><strong >Notice</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;16.4.</p>
            <p className="col--break"><strong >Partnership Agreement</strong>&rdquo; means any direct sales partner agreement entered into between any Person and Vigilant that directly refers to and incorporates these Terms.</p>
            <p className="col--break"><strong >Party</strong>&rdquo; has the meaning set forth in the preamble to these Terms.</p>
            <p className="col--break"><strong >Patents</strong>&rdquo; means all patents (including all reissues, divisionals, provisionals, continuations, and continuations-in-part, re-examinations, renewals, substitutions, and extensions thereof), patent applications, and other patent rights and any other Governmental Authority-issued indicia of invention ownership (including inventor&rsquo;s certificates, petty patents, and patent utility models).</p>
            <p className="col--break"><strong >Person</strong>&rdquo; means any individual, partnership, corporation, trust, limited liability entity, unincorporated organization, association, Governmental Authority, or any other entity.</p>
            <p className="col--break"><strong >Personnel</strong>&rdquo; means agents, employees, or subcontractors engaged or appointed by Vigilant or Direct Sales Partner.</p>
            <p className="col--break"><strong >Products</strong>&rdquo; means those products that are identified in <u>Schedule 2</u>, as Vigilant may amend in its sole discretion from time to time.&nbsp;</p>
            <p className="col--break"><strong >Purchase Contract</strong>&rdquo; means a contract or arrangement entered into between the Vigilant and a Customer for the sale of Products in the Territory by Vigilant to such Customer.</p>
            <p className="col--break"><strong >Receiving Party</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;10.1.</p>
            <p className="col--break"><strong >Renewal Term</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;9.2.</p>
            <p className="col--break"><strong >Representatives</strong>&rdquo; means a Party&rsquo;s Affiliates, employees, officers, directors, partners, managers, shareholders, members, agents, attorneys, third-party advisors, successors, and permitted assigns.</p>
            <p className="col--break"><strong >Direct Sales Partner</strong>&rdquo; has the meaning set forth in the preamble of these Terms.</p>
            <p className="col--break"><strong >Term</strong>&rdquo; has the meaning set forth in&nbsp;Section&nbsp;9.2.</p>
            <p className="col--break"><strong >Territory</strong>&rdquo; shall have the meaning ascribed to it in a relevant Partnership Agreement between Vigilant and a Direct Sales Partner. To the extent a Territory is not established in a Partnership Agreement, the Territory shall mean the area within a circle with a 50-mile radius extending from the Direct Sales Partner&rsquo;s sole and primary place of business.</p>
            <p className="col--break"><strong >Trademarks</strong>&rdquo; means all rights in and to US and foreign trademarks, service marks, trade dress, trade names, brand names, logos, trade dress, corporate names, and domain names and other similar designations of source, sponsorship, association, or origin, together with the goodwill symbolized by any of the foregoing, in each case whether registered or unregistered and including all registrations and applications for, and renewals or extensions of, such rights and all similar or equivalent rights or forms of protection in any part of the world.</p>

            </div>
        </li>
        <li> <h2>Appointment as Direct Sales Partner.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Non-Exclusive Appointment.</h3> Vigilant hereby appoints Direct Sales Partner, and Direct Sales Partner accepts such appointment, to act as a non-exclusive independent Direct Sales Partner of Products to Customers in the Territory during the Term, solely in accordance with the terms and conditions of these Terms. Vigilant may in its sole discretion directly or through an unaffiliated third party sell the Products to any Person, including Customers, Excluded Customers and other customers.</li>
            <li><h3>Excluded Customers.</h3> Notwithstanding Section 2.1, Vigilant does not appoint Direct Sales Partner as a Direct Sales Partner for the Excluded Customers. Direct Sales Partner shall not solicit orders from Excluded Customers and shall promptly refer to the Vigilant any sales leads it receives or becomes aware of relating to an Excluded Customer. Direct Sales Partner acknowledges and agrees that it is not entitled to any Commission or other compensation for any sale made by Vigilant to an Excluded Customer.</li>
            <li><h3>Status as Independent Contractor. </h3>
            <ol className="terms--alpha">
              <li>Direct Sales Partner is an independent contractor pursuant to these Terms.</li>
              <li>Nothing in these Terms creates any agency, joint venture, partnership, or other form of joint enterprise, employment, franchise, or fiduciary relationship between the Parties. Neither Party has any express or implied right or authority to assume or create any obligations on behalf of or in the name of the other Party or to bind the other Party to any obligation, contract, agreement, or undertaking with any Customer or other third party.</li>
              <li>The operations of the Direct Sales Partner are subject to the sole control of Direct Sales Partner. All Personnel of Direct Sales Partner are employees or Representatives of the Direct Sales Partner and not of Vigilant. Vigilant shall have no right or obligation to supervise the day-to-day conduct of Direct Sales Partner or its workers. Direct Sales Partner shall have sole control of the manner and means of performing under these Terms, subject to the terms and conditions of these Terms. Without limitation of the foregoing, Direct Sales Partner is solely responsible for, at its own expense:
                <ol className="terms--roman">
                  <li>providing such office space and facilities, and such Personnel (and their training) as may be necessary to carry out its obligations under these Terms;</li>
                  <li>compensating all Direct Sales Partner Personnel for any services rendered in connection with the performance of its obligations under these Terms;</li>
                  <li>covering all Personnel under any applicable social benefit laws (including workers’ compensation, unemployment insurance, social security, and applicable state disability insurance); and</li>
                  <li>making any and all payroll deductions and contributions that may be required by Law or otherwise with respect to the Personnel.</li>
                </ol>
              </li>
              <li>Direct Sales Partner shall be solely responsible for any and all costs or expenses, including travel and administrative costs, that it and any of its Personnel may incur in the performance of its obligations hereunder, as well as any and all taxes and fees associated with doing business as an independent contractor.
              </li>
            </ol>
            </li>
          </ol>
        </li>
        <li><h2>General Direct Sales Partner Obligations.</h2>
        <ol className="terms--content-list--level-2">
          <li> <h3>Market the Products.</h3> Direct Sales Partner shall at its own expense:
          <ol className="terms--alpha">
            <li>market, advertise, promote, and solicit the sale of the Products to prospective and existing Customers consistent with good business practice, in each case using its best efforts to maximize Product sales volume in the Territory;</li>
            <li>initiate and attend sales calls and meetings with prospective and existing Customers;</li>
            <li>develop and execute a sales and marketing plan sufficient to fulfill its obligations under these Terms;</li>
            <li>advertise in technical journals and in other publications or media, and participate in trade shows and exhibits selected by Vigilant;</li>
            <li>observe all directions and instructions given to it by Vigilant in relation to the marketing, advertisement, and promotion of the Products;</li>
            <li>in any and all contact between Direct Sales Partner and any prospective or existing Customer, identify Direct Sales Partner’s full legal name, trade name, or both;</li>
            <li>market, advertise, promote, and solicit the sale of the Products and conduct business in a manner that reflects favorably at all times on the Products and the good name, goodwill, and reputation of Vigilant;</li>
            <li>promptly notify Vigilant of, and provide, upon Vigilant’s request, assistance to Vigilant to address and investigate, any complaint or adverse claim about any Product or its use of which Direct Sales Partner becomes aware;</li>
            <li>promptly notify Vigilant of, and provide, upon Vigilant’s request, assistance to Vigilant to address and investigate, any circumstances indicating a Customer’s inability or refusal to make payment under any Purchase Contract of which Direct Sales Partner becomes aware;</li>
            <li>provide, upon Vigilant’s request, assistance to Vigilant to collect payment from Customers of amounts due under Purchase Contracts. Notwithstanding the above, Vigilant shall be responsible for all credit risk with respect to the Customers, and for collecting payment under all Purchase Contracts; and</li>
            <li>direct each Customer to pay only Vigilant, and promptly forward to Vigilant (without deduction) any amount received from a Customer, with endorsements if necessary.</li>
          </ol>
          </li>
          <li><h3>Provide Limited Customer Support.</h3> On and after the Effective Date, following the sale of a Product to any Customer, Direct Sales Partner shall, at its own expense: 
          <ol className="terms--alpha">
            <li>have sufficient knowledge of Vigilant’s Product, the industry, and products competitive with such Product (including specifications, features, and benefits) so as to be able to explain in detail to the Customers the differences between the Product and competing products;</li>
            <li>ensure that an adequate number of trained, capable, and qualified technical Personnel with sufficient knowledge of the Product are available during normal business hours to assist Customers; and</li>
            <li>respond to Customers with respect to the general operation and use of the Product, including:
              <ol className="terms--roman">
                <li>acting as a liaison between Customers and Vigilant in matters requiring Vigilant’s participation;</li>
                <li>providing general Product information and configuration support on standard protocols and features; and</li>
                <li>collecting relevant technical problem identification information.</li>
              </ol>
            </li>
          </ol>
          </li>
          <li><h3>Obtain Government Approval.</h3> Direct Sales Partner shall at its own expense remain in good standing and maintain all certifications, credentials, licenses, and permits necessary to conduct its business relating to its obligations under these Terms.</li>
          <li><h3>Prohibited Acts.</h3> Notwithstanding anything to the contrary in these Terms, neither Direct Sales Partner nor its Personnel shall directly or indirectly:
            <ol className="terms--alpha">
              <li>make any representations, warranties, guarantees, indemnities, similar claims, or other commitments: 
                <ol className="terms--roman">
                  <li>actually, apparently, or ostensibly on behalf of Vigilant, or</li>
                  <li>to any Customer with respect to the Products, which representations, warranties, guarantees, indemnities, similar claims, or other commitments are additional to or inconsistent with any then-existing representations, warranties, guarantees, indemnities, similar claims, or other commitments in these Terms or any online or written documentation made available from Vigilant to Customer;</li>
                </ol>
              </li>
              <li>engage in any unfair, anti-competitive, misleading, or deceptive practices respecting the Products, Vigilant or any third party, including product disparagement and any trade libel of Vigilant or any third party including any customer or competitor; </li>
              <li>separate any component, packaging, software, or accessory sold, bundled, or packaged with any Product from such Product or sell, license, or distribute such items on a standalone basis, or remove, translate, or modify the contents or documentation of or related to such items, including any Customer license agreements or warranty statements;</li>
              <li>sell, market, advertise, promote, solicit the sale of, or offer to sell any goods that compete with the products of Vigilant, including the Products, except to the extent this restriction is prohibited by applicable Law, and whether a product or product line is deemed to compete with the products of Vigilant is solely determined at the discretion of Vigilant; or</li>
              <li>modify, alter, reverse engineer, or otherwise change any of the Products or attempt to do so.</li>
            </ol>
          </li>
        </ol>
        </li>
        <li><h2>Vigilant Obligations.</h2>
        <ol className="terms--content-list--level-2">
          <li><h3>During the Term, Vigilant shall:</h3>
            <ol className="terms--alpha">
              <li>provide any information and support in the Territory that Vigilant usually makes available to its Direct Sales Partners as may be reasonably requested by Direct Sales Partner regarding the marketing, advertising, promotion, and sale of Products in the Territory under these Terms;</li>
              <li>in Vigilant’s discretion, allow Direct Sales Partner to participate in any marketing, advertising, promotion, and sales programs that Vigilant may make generally available to its authorized Direct Sales Partners of Products, provided that Vigilant shall have the right to alter or eliminate any such program at any time;</li>
              <li>approve or reject, in its sole discretion, any promotional information or material submitted by Direct Sales Partner for Vigilant’s approval within ten (10) days of receipt; </li>
              <li>provide reasonable quantities usually made available by Vigilant to its Direct Sales Partners and distributors of current promotional information and material for use by Direct Sales Partner in the Territory in accordance with these Terms; and</li>
              <li>keep records of all completed sales of Products through Direct Sales Partner hereunder and submit to Direct Sales Partner on or before the fifteenth (15th) day of each month (together with the applicable Commission payment under Section 6.2(b)) a monthly report (“Monthly Report”) indicating its total sales as to which Direct Sales Partner has earned Commissions during the immediately preceding month including the amount of payments received by Vigilant with respect to such sales for which Direct Sales Partner has earned Commissions under Section 6.2(a).</li>
            </ol>
          </li>
        </ol>

        </li>
        <li>
          <h2>Customer Orders.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Solicitation of Customer Purchase Orders.</h3> All purchase orders solicited by Direct Sales Partner from Customers are subject to acceptance, rejection, or modification by Vigilant under Section 5.2. Without limiting the generality of Section 2.3, Direct Sales Partner shall have no authority to enter into any Purchase Contract on behalf of Vigilant or to otherwise bind Vigilant to sell or deliver any Products to any Customer. Direct Sales Partner shall promptly notify Vigilant of all sales prospects in the Territory, and forward to Vigilant all Customer purchase orders, requests for quotation, and sales inquiries, even if received from persons outside the Territory. </li>
            <li><h3>Vigilant’s Discretion to Accept or Reject Customer Purchase Orders.</h3> Vigilant reserves the right, in its sole discretion, to:
              <ol className="terms--alpha">
                <li>accept, decline to accept, or negotiate directly with the Customer any modification to any purchase order for Products received from any party whether or not solicited by Direct Sales Partner;</li>
                <li>if Vigilant accepts a Customer purchase order, negotiate any terms and conditions of the Purchase Contract with Customer, including modifying Product features and quantities, and the purchase price, delivery, or payment terms; or</li>
                <li>cancel, terminate, or negotiate directly with the Customer to modify any Purchase Contract previously accepted by Vigilant.<br/> Direct Sales Partner acknowledges that Vigilant’s exercise of discretion may result in no Commission earned, or a reduction, or delay in the payment of Commission earned, for Direct Sales Partner under these Terms.</li>
              </ol>
            </li>
            <li><h3>Availability of Products.</h3> Vigilant may, in its sole discretion:
              <ol className="terms--alpha">
                <li>discontinue the sale of any of the Products upon ten (10) days’ advance written Notice thereof; and</li>
                <li>effect changes to any of the Products, packaging, labeling, or accessories thereto.</li>
              </ol>
            </li>
            <li><h3>Minimum Sales Quota.</h3> Direct Sales Partner shall meet the Minimum Sales Quota each calendar year of these Terms as revised annually by Vigilant. If Direct Sales Partner fails to achieve the Minimum Sales Quota, Vigilant has the right to immediately terminate these Terms.</li>
            
          </ol>
        </li>
        <li><h2>Commission</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Commission Rate.</h3> Subject to Section 6.2, Direct Sales Partner shall earn a commission (“Commission”) for completed sales of Products during the Term to Customers in the Territory that were solicited by Direct Sales Partner, subject to the terms of these Terms, at a percentage of the Net Sales Price under the applicable Purchase Contract as set forth in a Partnership Agreement.  In addition, Direct Sales Partner shall earn a Commission for Products that are renewed after the Term by Customers in the Territory that were solicited by Direct Sales Partner prior to the end of the Term so long as Direct Sales Partner has actively participated in maintaining Vigilant’s relationship with such Customer and in obtaining such renewal, except that this evergreen provision shall apply to only one post-Term subscription renewal period per such Customer.</li>
            <li><h3>Commission Payment Terms. </h3>
              <ol className="terms--alpha">
                <li>(a)	Commissions are earned by Direct Sales Partner only when Vigilant actually receives unconditional payment from Customer under the corresponding Purchase Contract. If Customer makes payments to Vigilant in increments or installments, the Commission shall be earned by Direct Sales Partner only upon full payment of the entire Purchase Contract, but Vigilant may, in its discretion, pay pro rata portions of the Commission in the next month following any of the incremental or installment payments by the Customer toward the Net Sales Price.</li>
                <li>(b)	Vigilant shall pay Direct Sales Partner, by the fifteenth (15th) day of each month, the Commission earned for the immediately preceding month.</li>
                <li>(c)	Vigilant shall make all Commission payments in US dollars by check or wire transfer. </li>
                <li>(d)	In addition, for purposes of clarification, Direct Sales Partner shall not be entitled to any Commission or other compensation:
                  <ol className="terms--roman">
                    <li>corresponding to any amount of the purchase price of a Purchase Contract that is not part of the Net Sales Price;</li>
                    <li>for any sale made to an Excluded Customer;</li>
                    <li>to the extent that Vigilant takes any action that reduces the Net Sales Price payable by any Customer pursuant to Section 5.2 or otherwise; or</li>
                    <li>that contravenes any Law.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li><h3>Disputes.</h3>Direct Sales Partner shall notify Vigilant in writing of any dispute regarding any Monthly Report (along with substantiating documentation) within ten (10) days from the date of such Monthly Report. Direct Sales Partner will be deemed to have accepted and agreed with all Monthly Reports for which Vigilant does not receive timely notification of disputes. The Parties shall seek to resolve all such disputes expeditiously and in good faith. Notwithstanding anything to the contrary, Direct Sales Partner shall continue performing its obligations under these Terms during any such dispute.</li>
            <li><h3>Setoff Right. </h3>Vigilant may withhold payment of and charge back to Direct Sales Partner any commissions earned under these Terms by reason of any setoff of any claim or dispute with Direct Sales Partner, relating to Direct Sales Partner’s breach or otherwise.</li>
          </ol>
        </li>
        <li><h2>Compliance with Laws.</h2> Direct Sales Partner shall at all times comply with all Laws related to Direct Sales Partner’s performance of this Contract. </li>
        <li><h2>Intellectual Property Rights.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Ownership.</h3> Direct Sales Partner acknowledges and agrees that:
              <ol className="terms--alpha">
                <li>any and all Vigilant’s Intellectual Property Rights are the sole and exclusive property of Vigilant or its licensors; </li>
                <li>Direct Sales Partner shall not acquire any ownership interest in any of Vigilant’s Intellectual Property Rights under these Terms;</li>
                <li>any goodwill derived from the use by Direct Sales Partner of Vigilant’s Intellectual Property Rights inures to the benefit of Vigilant or its licensors, as the case may be;</li>
                <li>if Direct Sales Partner acquires any Intellectual Property Rights in or relating to any Product purchased under these Terms (including any rights in any Trademarks, derivative works or patent improvements relating thereto), by operation of law, or otherwise, such rights are deemed and are hereby irrevocably assigned to Vigilant or its licensors, as the case may be, without further action by either of the Parties; and</li>
                <li>Direct Sales Partner shall use Vigilant’s Intellectual Property Rights solely for the purposes of performing its obligations under these Terms and only in accordance with these Terms and the instructions of Vigilant.</li>
              </ol>
            </li>
            <li><h3>Vigilant’s Trademark License Grant.</h3> Subject to Vigilant’s trademark guidelines posted at www.vigilantnow.com/brandguidelines, which may be amended from time to time in Vigilant’s sole discretion, and the terms and conditions of these Terms, Vigilant hereby grants to Direct Sales Partner a non-exclusive, non-transferable, and non-sublicensable license to use Vigilant’s Trademarks in the Territory during the Term solely on or in connection with the marketing, promotion, advertising, and sale of the Products. Direct Sales Partner will promptly discontinue the display or use of any Trademark to change the manner in which a Trademark is displayed or used with regard to the Products when requested by Vigilant.</li>
            <li><h3>Marketing and Resale Right Only.</h3> Direct Sales Partner is authorized to market the Products only in the form as authorized by Vigilant to Direct Sales Partner. Other than the express licenses granted by these Terms, Vigilant grants no right or license to Direct Sales Partner by implication, estoppel, or otherwise to the Products or any of Vigilant’s Intellectual Property Rights.</li>
            <li><h3>Prohibited Acts.</h3> Direct Sales Partner shall not, and shall not cause or encourage Customers to:
              <ol className="terms--alpha">
                <li>take any action that may interfere with any of Vigilant’s rights in or to Vigilant’s Intellectual Property Rights, including Vigilant’s ownership or exercise thereof;</li>
                <li>challenge any right, title, or interest of Vigilant in or to Vigilant’s Intellectual Property Rights;</li>
                <li>make any claim or take any action adverse to Vigilant’s ownership of Vigilant’s Intellectual Property Rights;</li>
                <li>register or apply for registrations, anywhere in the world, for Vigilant’s Trademarks or any other Trademark that is similar to any of Vigilant’s Trademarks or that incorporates any of Vigilant’s Trademarks in whole or in confusingly similar part;</li>
                <li>use any mark, anywhere, that is confusingly similar to Vigilant’s Trademarks;</li>
                <li>engage in any action that tends to disparage, dilute the value of, or reflect negatively on the products purchased under these Terms (including Products) or any Vigilant Trademark; </li>
                <li>misappropriate any of Vigilant’s Trademarks for use as a domain name without prior written consent from Vigilant; or</li>
                <li>alter, obscure, or remove any of Vigilant’s Trademarks or trademark or copyright notices or any other proprietary rights notices placed on the products purchased under these Terms (including Products), marketing materials, or other materials that Vigilant may provide.</li>
              </ol>
            </li>
            <li><h3>No Continuing Rights.</h3> On expiration or earlier termination of these Terms, Direct Sales Partner will immediately cease all display, advertising, promotion, and use of all of Vigilant’s Trademarks and will not thereafter use, advertise, promote, or display any Trademark or any part thereof that is similar to or confusing with Vigilant’s Trademarks or with any other Trademark associated with Vigilant or any Product.</li>
          </ol>
        </li>
        <li>
          <h2>Term; Termination.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Initial Term.</h3> Unless stated otherwise in a Partnership Agreement, the term of a Partnership Agreement continues for a period of  one (1) year unless and until terminated as provided under these Terms (the “Initial Term”).</li>
            <li><h3>Renewal Term.</h3> Upon expiration of the Initial Term, a Partnership Agreement automatically renews for additional successive one (1) year terms unless and until Vigilant provides written Notice of nonrenewal at least thirty (30) days prior to the end of the then-current term (each a “Renewal Term” and together with the Initial Term, the “Term”), or unless and until sooner terminated as provided under these Terms. If the Term is renewed for any Renewal Term(s) pursuant to this Section 9.2, the terms and conditions of these Terms during each such Renewal Term are the same as the terms in effect immediately prior to such renewal. In the event Vigilant provides timely Notice of its intent not to renew these Terms, then, unless earlier terminated in accordance with its terms, these Terms terminates on the expiration of the Initial Term or then-current Renewal Term, as applicable.</li>
            <li><h3>Vigilant’s Right to Terminate.</h3> Vigilant may terminate a Partnership Agreement by providing written Notice to Direct Sales Partner:
              <ol className="terms--alpha">
                <li>for any reason or no reason, which termination shall become effective no less than thirty (30) days after delivery of the written Notice; or</li>
                <li>if Direct Sales Partner breaches any provision of these Terms or a Partnership Agreement; or</li>
                <li>pursuant to and in accordance with Section 5.4; or</li>
                <li>if Direct Sales Partner becomes insolvent or files, or has filed against it, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency Law, makes or seeks to make a general assignment for the benefit of its creditors or applies for, or consents to, the appointment of a trustee, receiver, or custodian for a substantial part of its property, or is generally unable to pay its debts as they become due; or</li>
                <li>if Direct Sales Partner sells, transfers, or disposes of all or substantially all of its assets, or merges or consolidates with any other entity.</li>
              </ol>
            </li>
            <li>
              <h3>Effect of Expiration or Termination.</h3>
                <ol className="terms--alpha">
                  <li>
                  Expiration or termination of the Term will not affect any rights or obligations that: 
                    <ol className="terms--roman">
                      <li>are to survive the expiration or earlier termination of these Terms pursuant to Section 16.3; and</li>
                      <li>were incurred by the Parties prior to such expiration or earlier termination; provided that Vigilant shall not have any obligation to pay Commission with respect to any Products ordered but not delivered prior to expiration or termination.</li>
                    </ol>
                  </li>
                  <li>
                  Upon the expiration or earlier termination of a Partnership Agreement, Direct Sales Partner shall promptly:
                    <ol className="terms--roman">
                      <li>cease to represent itself as Vigilant’s authorized Direct Sales Partner with respect to the Products, and shall otherwise desist from all conduct or representations that might lead the public to believe that Direct Sales Partner is authorized by Vigilant to market, promote or solicit sales of the Products;</li>
                      <li>destroy all documents and tangible materials (and any copies) containing, reflecting, incorporating, or based on Vigilant’s Confidential Information;</li>
                      <li>permanently erase all of Vigilant’s Confidential Information from its computer systems, except for copies that are maintained as archive copies on its disaster recovery and/or information technology backup systems. Direct Sales Partner shall destroy any such copies upon the normal expiration of its backup files; and</li>
                      <li>certify in writing to Vigilant that it has complied with the requirements of this clause (b).</li>
                    </ol>
                  </li>
                </ol>
            </li>
          </ol>
        </li>
        <li>
        <h2>Confidentiality.</h2>
        <ol className="terms--content-list--level-2">
          <li><h3>Scope of Confidential Information.</h3> From time to time during the Term, Vigilant (as the “Disclosing Party”) may disclose or make available to Direct Sales Partner (as the “Receiving Party”) information about its business affairs, goods and services, forecasts, confidential information, and materials comprising or relating to Intellectual Property Rights, trade secrets, third-party confidential information, and other sensitive or proprietary information, as well as the terms of these Terms, whether orally or in written, electronic, or other form or media, and whether or not marked, designated, or otherwise identified as “confidential” (collectively, “Confidential Information”). Confidential Information does not include information that, at the time of disclosure and as established by documentary evidence: 
            <ol className="terms--alpha">
              <li>is or becomes generally available to and known by the public other than as a result of, directly or indirectly, any breach of this Section 10.1 by the Receiving Party or any of its Representatives;</li>
              <li>is or becomes available to the Receiving Party on a non-confidential basis from a third-party source, provided that such third party is not and was not prohibited from disclosing such Confidential Information; </li>
              <li>was known by or in the possession of the Receiving Party or its Representatives prior to being disclosed by or on behalf of the Disclosing Party;</li>
              <li>was or is independently developed by the Receiving Party without reference to or use of, in whole or in part, any of the Disclosing Party’s Confidential Information; or </li>
              <li>is required to be disclosed pursuant to applicable Law. </li>
            </ol>
          </li>
          <li><h3>Protection of Confidential Information.</h3> The Receiving Party shall, for ten (10) years from disclosure of such Confidential Information except as set out in Section 16.3 with respect to trade secrets:
            <ol className="terms--alpha">
              <li>protect and safeguard the confidentiality of the Disclosing Party’s Confidential Information with at least the same degree of care as the Receiving Party would protect its own Confidential Information, but in no event with less than a commercially reasonable degree of care; </li>
              <li>not use the Disclosing Party’s Confidential Information, or permit it to be accessed or used, for any purpose other than to exercise its rights or perform its obligations under these Terms; and </li>
              <li>not disclose any such Confidential Information to any Person, except to the Receiving Party’s Representatives who need to know the Confidential Information to assist the Receiving Party, or act on its behalf, to exercise its rights or perform its obligations under these Terms.<br/> <br/>The Receiving Party shall be responsible for any breach of this Section 10 caused by any of its Representatives. On the expiration or earlier termination of these Terms, or at any time upon the Disclosing Party’s written request, the Receiving Party and its Representatives shall, pursuant to Section 9.4(b), promptly destroy all Confidential Information and copies thereof that it has received under these Terms.</li>
            </ol>
          </li>
        </ol>
        </li>
        <li>
          <h2>Representations and Warranties.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Direct Sales Partner’s Representations and Warranties.</h3> Direct Sales Partner represents and warrants to Vigilant that:
              <ol className="terms--alpha">
                <li>it is a an entity duly organized, validly existing, and in good standing in the jurisdiction of its formation;</li>
                <li>it is qualified and licensed to do business and in good standing in every jurisdiction where such qualification and licensing is required;</li>
                <li>it has the full right, power, and authority to enter into these Terms, to grant the rights and licenses granted under these Terms and to perform its obligations under these Terms;</li>
                <li>the execution and performance of these Terms by Direct Sales Partner will not breach or in any other way violate any other agreement, understanding, or duty of Direct Sales Partner to any other person;</li>
                <li>the execution of these Terms by its Representative whose signature is set forth at the end hereof has been duly authorized by all necessary corporate action of Direct Sales Partner; and</li>
                <li>when executed and delivered by each of Vigilant and Direct Sales Partner, these Terms will constitute the legal, valid, and binding obligation of Direct Sales Partner, enforceable against Direct Sales Partner in accordance with its terms, subject to the effect of any applicable bankruptcy, insolvency, reorganization, moratorium, or similar laws affecting creditors’ rights generally or the effect of general principles of equity (regardless of whether considered in a proceeding at law or in equity).</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>Indemnification.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Indemnification.</h3> Direct Sales Partner (as “Indemnifying Party”) shall indemnify, hold harmless, and defend Vigilant and its officers, managers, members, partners, employees, agents, Affiliates, successors, and assigns (collectively, “Indemnified Party”) against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorney fees, fees and the costs of enforcing any right to indemnification under these Terms, and the cost of pursuing any insurance providers, incurred by Indemnified Party (collectively, “Losses”), relating to, arising out of or resulting from any third-party Claim alleging:
              <ol className="terms--alpha">
                <li>breach or non-fulfillment of any representation, warranty, or covenant of these Terms by Indemnifying Party or Indemnifying Party’s Personnel;</li>
                <li>any negligent or more culpable act or omission of Indemnifying Party or its Personnel (including any recklessness or willful misconduct) in connection with the performance of its obligations under these Terms; or</li>
                <li>any bodily injury, death of any Person or damage to real or tangible personal property caused by the acts or omissions of Indemnifying Party or its Personnel.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
        <h2>Non-solicitation.</h2>
        During the Term and for a period of twelve (12) months thereafter, Direct Sales Partner shall not, and shall not permit its Representatives to, directly or indirectly, in any manner make any solicitation to employ the Vigilant’s Personnel without written consent of Vigilant, to be given or withheld in Vigilant’s sole discretion. 
        </li>
        <li>
          <h2>Limitation of Liability.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>NO LIABILITY FOR CONSEQUENTIAL OR INDIRECT DAMAGES. </h3> IN NO EVENT SHALL VIGILANT OR ITS REPRESENTATIVES BE LIABLE FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF OR RELATING TO ANY BREACH OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE FORESEEABLE, (B) WHETHER OR NOT VIGILANT WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</li>
            <li><h3>MAXIMUM LIABILITY FOR DIRECT DAMAGES.</h3> IN NO EVENT SHALL VIGILANT’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID BY VIGILANT PURSUANT TO THESE TERMS IN THE TWELVE-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.</li>
          </ol>
        </li>
        <li><h2>Insurance.</h2> During the Term, Direct Sales Partner shall, at its own expense, maintain and carry in full force and effect all types and amounts of insurance required by applicable Law, including commercial general liability (including product liability) with limits of at least $1,000,000, auto insurance, and workers’ compensation, in each case with financially sound and reputable insurers. Direct Sales Partner shall provide Vigilant with a certificate of insurance from Direct Sales Partner’s insurer evidencing the insurance coverage specified in these Terms and shall cause the certificate of insurance to name Vigilant as an additional insured. Direct Sales Partner shall provide Vigilant with thirty (30) days’ advance written Notice in the event of a cancellation or material change in its insurance policy. Except where prohibited by Law, Direct Sales Partner shall require its insurer to waive all rights of subrogation against Vigilant and its insurers.</li>
        <li>
          <h2>Miscellaneous.</h2>
          <ol className="terms--content-list--level-2">
            <li><h3>Further Assurances. </h3> Upon a Party’s reasonable request, the other Party shall, at its sole cost and expense, execute and deliver all such further documents and instruments, and take all such further acts, necessary to give full effect to these Terms.</li>
            <li>
              <h3>Entire Agreement.</h3>
              <ol className="terms--alpha">
                <li>(a)	The Partnership Agreement and these Terms, including and together with all exhibits, schedules, attachments, and appendices, constitutes the sole and entire agreement of the Parties with respect to the subject matter contained herein and therein, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. To the extent there is a conflict between these Terms and any Partnership Agreement, any exhibits, schedules, attachments, or any other document or agreement between Vigilant and Direct Sales Partner, these Terms shall govern.</li>
                <li>(b)	Without limitation of anything contained in Section 16.2(a), Direct Sales Partner acknowledges that except for the representations and warranties contained in Section 11.1, neither Vigilant nor any other Person has made or makes, and expressly disclaims, any other express or implied representation or warranty, either written or oral, on behalf of Vigilant, including any representation or warranty regarding merchantability, fitness for a particular purpose, title, or non-infringement or arising from statute or otherwise in Law.</li>
              </ol>
            </li>
            <li>
            <h3>Survival.</h3> Subject to the limitations and other provisions of these Terms: (a) the representations and warranties of Direct Sales Partner contained herein shall survive the expiration or earlier termination of these Terms; and (b) Sections 8.1, 9.4(b), 10, 12, 13, 14, and 16 of these Terms, as well as any other provision that, in order to give proper effect to its intent, should survive such expiration or termination, shall survive the expiration or earlier termination of these Terms for the period specified therein. With respect to Confidential Information that constitutes a trade secret under applicable law, the rights and obligations set forth in Section 10 hereof will survive the expiration or earlier termination of these Terms until, if ever, such Confidential Information loses its trade secret protection other than due to an act or omission of the Receiving Party or the Receiving Party’s Representatives.
            </li>
            <li>
            <h3>Notices.</h3>Each Party shall deliver all notices, requests, consents, claims, demands, waivers and other communications under these Terms (each, a “Notice”) in writing and addressed to the other Party at its address set forth below (or to such other address that the receiving Party may designate from time to time in accordance with this section). Each Party shall deliver all Notices by personal delivery, nationally recognized overnight courier, facsimile, electronic mail, or certified or registered mail (in each case, return receipt requested, postage prepaid). Except as otherwise provided in these Terms, a Notice is effective only (a) on receipt by the receiving Party, and (b) if the Party giving the Notice has complied with the requirements of this Section.
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <h4>Notice to Vigilant:</h4>
                  </div>
                  <div className="col-12 col-md-5">
                  Vigilant LLC<br/>7570 Bales Street, Suite 250<br/>Liberty Township, Ohio 45069
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <h4>Notice to Direct Sales Partner:</h4>
                  </div>
                  <div className="col-12 col-md-5">
                  Direct Sales Partner shall provide its principal place of business address and an e-mail under which it agrees to receive service and notice under these Terms or any Partnership Agreement.
                  </div>
                </div>
              </div>
            </li>
            <li>
            <h3>Interpretation. </h3> For purposes of these Terms: (a) the words “include,” “includes” and “including” are deemed to be followed by the words “without limitation”; (b) the word “or” is not exclusive; (c) the words “herein,” “hereof,” “hereby,” “hereto,” and “hereunder” refer to these Terms as a whole; (d) words denoting the singular have a comparable meaning when used in the plural, and vice-versa; and (e) words denoting any gender include all genders. Unless the context otherwise requires, references in these Terms: (x) to sections, exhibits, schedules, attachments, and appendices mean the sections of, and exhibits, schedules, attachments, and appendices attached to, these Terms; (y) to an agreement, instrument, or other document means such agreement, instrument, or other document as amended, supplemented, and modified from time to time to the extent permitted by the provisions thereof; and (z) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder. The Parties drafted these Terms without regard to any presumption or rule requiring construction or interpretation against the Party drafting an instrument or causing any instrument to be drafted. The exhibits, schedules, attachments, and appendices referred to herein are an integral part of these Terms to the same extent as if they were set forth verbatim herein.
            </li>
            <li><h3>Headings.</h3> The headings in these Terms are for reference only and do not affect the interpretation of these Terms.</li>
            <li><h3>Severability.</h3> If any term or provision of these Terms is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability does not affect any other term or provision of these Terms or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon a determination that any term or provision is invalid, illegal, or unenforceable, the court may modify these Terms to effect the original intent of the Parties as closely as possible in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</li>
            <li><h3>Amendment and Modification.</h3> No amendment to a Partnership Agreement is effective unless it is in writing and signed by each Party. </li>
            <li>
              <h3>Waiver.</h3>
              <ol className="terms--alpha">
                <li>(a)	No waiver under these Terms or a Partnership Agreement is effective unless it is in writing and signed by the Party waiving its right.</li>
                <li>(b)	Any waiver authorized on one occasion is effective only in that instance and only for the purpose stated, and does not operate as a waiver on any future occasion.</li>
                <li>(c)	None of the following constitutes a waiver or estoppel of any right, remedy, power, privilege, or condition arising from these Terms:
                  <ol className="terms--roman">
                    <li>any failure or delay in exercising any right, remedy, power, or privilege or in enforcing any condition under these Terms; or</li>
                    <li>any act, omission, or course of dealing between the Parties.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
            <h2>Cumulative Remedies.</h2> All rights and remedies provided in these Terms are cumulative and not exclusive, and the exercise by either Party of any right or remedy does not preclude the exercise of any other rights or remedies that may now or subsequently be available at law, in equity, by statute, in any other agreement between the Parties, or otherwise. 
            </li>
            <li>
            <h3>Equitable Remedies.</h3> Direct Sales Partner acknowledges and agrees that (a) a breach or threatened breach by Direct Sales Partner of any of its obligations under Section 10 would give rise to irreparable harm to Vigilant for which monetary damages would not be an adequate remedy and (b) in the event of a breach or a threatened breach by Direct Sales Partner of any such obligations, Vigilant shall, in addition to any and all other rights and remedies that may be available to Vigilant at law, in equity, or otherwise in respect of such breach, be entitled to equitable relief, including a temporary restraining order, an injunction, specific performance, and any other relief that may be available from a court of competent jurisdiction, without any requirement to post a bond or other security, and without any requirement to prove actual damages or that monetary damages will not afford an adequate remedy.  Direct Sales Partner agrees that Direct Sales Partner will not oppose or otherwise challenge the appropriateness of equitable relief or the entry by a court of competent jurisdiction of an order granting equitable relief, in either case, consistent with the terms of this Section 16.11.
            </li>
            <li>
            <h3>Assignment.</h3> Direct Sales Partner may not assign any of its rights or delegate any of its obligations under a Partnership Agreement or these Terms without the prior written consent of Vigilant. Any purported assignment or delegation in violation of this Section is null and void. No assignment or delegation relieves the assigning or delegating Party of any of its obligations under these Terms. Vigilant may assign any of its rights or delegate any of its obligations without Direct Sales Partner’s consent.
            </li>
            <li>
            <h3>Successors and Assigns.</h3> These Terms is binding on and inures to the benefit of the Parties and their respective permitted successors and permitted assigns. 
            </li>
            <li>
            <h3>No Third-party Beneficiaries.</h3> The parties do not confer any rights or remedies upon any Person other than the parties to these Terms and their respective successors and permitted assigns.
            </li>
            <li>
            <h3>Choice of Law.</h3> These Terms, including all exhibits, schedules, attachments, and appendices attached hereto and thereto, and all matters arising out of or relating to these Terms, are governed by, and construed in accordance with, the Laws of the State of Ohio, without regard to any conflict of laws provisions.
            </li>
            <li>
            <h3>Choice of Forum.</h3> Each Party irrevocably and unconditionally agrees that it will not commence any action, litigation or proceeding of any kind whatsoever against the other Party in any way arising from or relating to these Terms, including all exhibits, schedules, attachments, and appendices attached hereto and thereto, and all contemplated transactions, in any forum other than the federal or state courts sitting in Butler County, Ohio, and any appellate court from any thereof. Each Party irrevocably and unconditionally submits to the exclusive jurisdiction of such courts and agrees to bring any such action, litigation, or proceeding only in such courts. Each Party agrees that a final judgment in any such action, litigation, or proceeding is conclusive and may be enforced in other jurisdictions by suit on the judgment or in any other manner provided by Law.
            </li>
            <li>
            <h3>Waiver of Jury Trial.</h3> Each Party acknowledges and agrees that any controversy that may arise under these Terms, including any exhibits, schedules, attachments, and appendices attached to these Terms, is likely to involve complicated and difficult issues and, therefore, each such Party irrevocably and unconditionally waives any right it may have to a trial by jury in respect of any legal action arising out of or relating to these Terms, including any exhibits, schedules, attachments, and appendices attached to these Terms, or the transactions contemplated hereby.
            </li>
            <li>
            <h3>Counterparts.</h3> These Terms may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement. A signed copy of these Terms delivered by facsimile, e-mail, or other means of electronic transmission is deemed to have the same legal effect as delivery of an original signed copy of these Terms.
            </li>
            <li>
            <h3>Force Majeure.</h3> No Party shall be liable or responsible to the other Party, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any term of these Terms when and to the extent such failure or delay is caused by or results from acts beyond the impacted party’s (“Impacted Party”) reasonable control, including the following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, earthquake, pandemic, or explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or actions; (e) embargoes or blockades in effect on or after the date of these Terms; (f) national or regional emergency; (g) strikes, labor stoppages or slowdowns, or other industrial disturbances; or (h) shortage of adequate power or transportation facilities. The Impacted Party shall give notice within five (5) days of the Force Majeure Event to the other Party, stating the period of time the occurrence is expected to continue. The Impacted Party shall use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure Event are minimized. The Impacted Party shall resume the performance of its obligations as soon as reasonably practicable after the removal of the cause. In the event that the Impacted Party’s failure or delay remains uncured for a period of thirty (30) consecutive days following written notice given by it under this Section 16.19, the other Party may thereafter terminate these Terms upon written notice.
            </li>
            <li>
            <h3>No Public Announcements.</h3> Unless expressly permitted under these Terms, Direct Sales Partner shall not make any statement (whether oral or in writing) in any press release, external advertising, marketing, or promotion materials regarding Vigilant or its business unless:
            <ol className="terms--alpha">
              <li>it has received the express written consent of Vigilant, or </li>
              <li>it is required to do so by Law.</li>
            </ol>
            </li>
          </ol>
        </li>
    </ol>
    <div className="container">
      <div className="row">
        <div className="col-12">
        <p>76702979v1</p>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </section>
  </Layout>
)

export default DirectTermsPage
